export default {
    methods: {
        newShade (hexColor: string, magnitude:any): string {
            const color = hexColor.replace('#', '');

            const decimalColor = parseInt(color, 16);
            let r = (decimalColor >> 16) + magnitude;
            r = r > 255 ? 255 : r < 0 ? 0 : r;
            let g = (decimalColor >> 8 & 0x00ff) + magnitude; // Corrected line
            g = g > 255 ? 255 : g < 0 ? 0 : g;
            let b = (decimalColor & 0x0000ff) + magnitude;
            b = b > 255 ? 255 : b < 0 ? 0 : b;

            return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
        },
    }
};