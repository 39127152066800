<template>
    <div style="display: flex;height: 100%;padding-right: 10px;justify-content: right;width: fit-content">
        <div class="second-display-container">
        <div v-if="data.length" class="title-box justify-content-between">
            <div class="box-title" style="justify-content: left;">
                <div style="margin-top: auto;margin-bottom: auto;">{{ firstTitle }}</div>
            </div>
            <div class="box-title">
                <div style="margin: auto">{{ secondTitle }}</div>
            </div>
        </div>
        <div v-for="(office,index) in data" style="display: flex;justify-content: right" :key="index">
            <div class="show-box">
                <div class="box-first-value">
                    <div :id="'identifier-'+office.public_identifier.replace(' ','')" class="value">{{ office.public_identifier.replace(' ','') }}</div>
                </div>
            </div>
            <div class="show-box" style="margin-right: 2vmin;width: 60%">
                <div class="box-value box-second-value">
                    <div :id="'counter-'+office.counter_name.replace(' ','')" class="value">{{ office.counter_name }}</div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'LbrxDisplaySecondTheme',
    props: {
        firstTitle: {type: String, required: true},
        secondTitle: {type: String, required: true},
        firstPrimaryColor: {type: String, required: true},
        secondPrimaryColor: {type: String, required: true},
        secondaryColor: {type: String, required: true},
        secondaryTextColor: {type: String, required: true},
        data: {type: Array, required: true}
    },
    data() {
        return {
            offices: this.data,
        }
    }, methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },

    computed: {
        isBigWidth() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },

        getBigWidth() {
            let bigWidth = 3;
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length >bigWidth) {
                    bigWidth = this.data[i].public_identifier.length;
                }
            }
            return 100+(345/bigWidth)+'%'
        },
        lineHeight() {
            if(this.data.length > 3) {
                return 68 / this.data.length + "vh";
            }
            if(this.data.length === 3) {
                return 60 / this.data.length + "vh";
            }
            else if(this.data.length === 2) {
                if(this.isBigWidthMethod()) {
                    return 38 / this.data.length + "vh";
                }
                return 38 / this.data.length + "vh";
            }
            else {
                if(this.isBigWidthMethod()) {
                    return 18 / this.data.length + "vh";
                }
                return 18 / this.data.length + "vh";
            }

        },
        valueSize() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 3.6+'vw';
                return 5.8+"vw";
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 3.5 + 'vw';
                return 6 + 'vw';
            }
            if(this.isBigWidthMethod())
                return 3.2+'vw';
            return 17/this.data.length+"vw";
        },
    }
}
</script>

<style scoped>
.show-box {
    margin-bottom: 2vmin;
    height: v-bind(lineHeight);
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.box-title {
    width: 100%;
    font-family: 'Exo 2', serif;
    font-size: 7vmin;
    display: flex;
    justify-content: center;
    background: linear-gradient(127.19deg, v-bind(firstPrimaryColor) 0.56%, v-bind(secondPrimaryColor) 76.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
}

.box-second-value {
    padding-left: 16%;
    height: 100%;
    width: 100%;
    min-width: 270px;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    background: v-bind(secondaryColor);
    color: v-bind(secondaryColor);
    border-top-right-radius: 7.4vmin;
}

.box-second-value .value {
    margin: auto;
    background: linear-gradient(127.19deg, v-bind(firstPrimaryColor) 0.56%, v-bind(secondPrimaryColor) 76.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.box-first-value {
    z-index: 99;
    height: v-bind(lineHeight);
    width: 120% !important;
    min-width: 270px;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    background: linear-gradient(127.19deg, v-bind(firstPrimaryColor) 0.56%, v-bind(secondPrimaryColor) 76.01%);
    color: v-bind(secondaryTextColor);
    border-bottom-left-radius: 7.4vmin;
    border-top-right-radius: 7.4vmin;
}
.box-first-value .value {
    width: fit-content !important;
    margin: auto !important;
}
.second-display-container {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content !important;
}

.title-box {
    display: flex;
    width: 100%;
}
</style>