<template>
    <div style="display: flex;height: 100%;justify-content: right !important;padding-right: 20px">
        <div v-if="data" class="first-theme-display-container">
            <div v-for="(office,index) in data" class="row" style="margin-bottom: 2vmin" :key="index">
                <div style="display: flex">
                    <div class="show-box" style="width: 100% !important;">
                        <div class="box-title" style="border-top-left-radius: 20px">
                            <div style="margin: auto">{{ firstTitle }}</div>
                        </div>
                        <div class="box-value" style="border-bottom-left-radius: 20px;word-break: keep-all !important;">
                            <div :id="'identifier-'+office.public_identifier.replace(' ','')" class="value" style="width: fit-content !important;word-break: keep-all !important;">{{ office.public_identifier.replace(' ','') }}</div>
                        </div>
                    </div>
                    <div class="show-box">
                        <div class="box-title" style="border-top-right-radius: 20px">
                            <div style="margin: auto">{{ secondTitle }}</div>
                        </div>
                        <div class="box-value" style="border-bottom-right-radius: 20px">
                            <div :id="'counter-'+office.counter_name.replace(' ','')" class="value">{{ office.counter_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LbrxDisplaySingleBlocTheme',
    props: {
        firstTitle: {type: String, required: true},
        secondTitle: {type: String, required: true},
        firstPrimaryColor: {type: String, required: true},
        secondPrimaryColor: {type: String, required: true},
        secondaryColor: {type: String, required: true},
        secondaryTextColor: {type: String, required: true},
        data: {type: Array, required: true}
    },
    data() {
        return {
        }
    },
    methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },
    computed: {
        lineHeight() {
            if(this.data.length > 1)
                return 70/this.data.length+"vh";
            return 35+"vh";
        },
        valueSize() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 3.6+'vw';
                return 5.8+"vw";
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 3.5 + 'vw';
                return 6 + 'vw';
            }
            if(this.isBigWidthMethod())
                return 3.2+'vw';
            return 17/this.data.length+"vw";
        },
        lineWidth() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 42+'vmin';
                return 37+'vmin';
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 42+'vmin';
                return 35 + 'vmin';
            }
            if(this.isBigWidthMethod())
                return 125/this.data.length+"vmin"
            return 100/this.data.length+"vmin";
        },
        titleSize() {
            if(this.data.length < 3)
                return 2.8+'vw';
            return 13.6/this.data.length+"vmin";
        },
    }
}
</script>

<style scoped>
.show-box {
    margin-right: 0;
    margin-bottom: 1vmin;
    height: v-bind(lineHeight);
    width: fit-content;
    min-width: 270px;
    display: flex;
    flex-direction: column
}

.box-title {
    font-family: 'Exo 2', serif;
    height: 40%;
    font-size: v-bind(titleSize);
    display: flex;
    justify-content: center;
    background: linear-gradient(127.19deg, v-bind(secondPrimaryColor) 0.56%, v-bind(secondPrimaryColor) 76.01%);
    color: v-bind(secondaryTextColor);
}

.box-value {
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    background: v-bind(secondaryColor);
}

.box-value .value {
    margin: auto;
    background: linear-gradient(127.19deg, v-bind(firstPrimaryColor) 0.56%, v-bind(firstPrimaryColor) 76.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.first-theme-display-container {
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    justify-content: right;
}
</style>