<template>
    <div style="display: flex;height: 100%;padding-right: 20px;">
    <div v-if="data" class="first-theme-display-container">
        <table class="display-table">
            <tr v-for="(office,index) in data" :key="index">
                <td>
                    <div class="show-box">
                        <div class="box-title">
                            {{ firstTitle }}
                        </div>
                        <div class="box-value">
                            <div :id="'identifier-'+office.public_identifier.replace(' ','')" class="value">{{ office.public_identifier.replace(' ','') }}</div>
                        </div>
                    </div>
                </td>
                <td style="padding-left: 2vmin;padding-right: 2vmin">
                    <div class="show-box">
                        <div class="box-title">
                            {{ secondTitle }}
                        </div>
                        <div class="box-value">
                            <div :id="'counter-'+office.counter_name.replace(' ','')" class="value">{{ office.counter_name }}</div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    </div>
</template>

<script>

export default {
    name: 'LbrxDisplayFirstTheme',
    props: {
        firstTitle: {type: String, required: true},
        secondTitle: {type: String, required: true},
        firstPrimaryColor: {type: String, required: true},
        secondPrimaryColor: {type: String, required: true},
        secondaryColor: {type: String, required: true},
        secondaryTextColor: {type: String, required: true},
        data: {type: Array, required: true}
    },
    data() {
        return {
        }
    },
    methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },
    computed: {
        isBigWidth() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },

        lineHeight() {
            if(this.data.length > 1)
                return 80/this.data.length+"vh";
            return "35vh";
        },
        valueSize() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 3.6+'vw';
                return 5.8+"vw";
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 3.5 + 'vw';
                return 6 + 'vw';
            }
            if(this.isBigWidthMethod())
                return 3.2+'vw';
            return 17/this.data.length+"vw";
        },
        lineWidth() {
            if(this.data.length === 1)
                return 37+'vmin';
            if(this.data.length === 2)
                return 40+'vmin';
            return 100/this.data.length+"vmin";
        },
        titleSize() {
            if(this.data.length < 3)
                return 6.8+'vmin';
            return 13.6/this.data.length+"vmin";
        },
    }
}
</script>

<style scoped>
.show-box {
    margin-bottom: 1vmin;
    height: v-bind(lineHeight);
    width: 100%;
    min-width:v-bind(lineWidth);
    border-radius: 20px;
    display: flex;
    flex-direction: column
}
.box-title {
    font-family: 'Exo 2', serif;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 40%;
    font-size: v-bind(titleSize);
    display: flex;
    justify-content: center;
    background: linear-gradient(127.19deg, v-bind(secondPrimaryColor) 0.56%, v-bind(secondPrimaryColor) 76.01%);
    color: v-bind(secondaryTextColor);
}

.box-value {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    background: v-bind(secondaryColor);
}

.box-value .value {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center !important;
    background: linear-gradient(127.19deg, v-bind(firstPrimaryColor) 0.56%, v-bind(firstPrimaryColor) 76.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.first-theme-display-container {
    width: fit-content;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: right;
}
</style>