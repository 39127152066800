<template>
    <div v-if="data" class="third-display-container">
        <div v-for="(office,index) in data" :key="index" :style="{'background' : index%2 === 0 ? primaryColor : secondaryColor,'padding-top': index === 0 && data.length>5 ? '3vmin': '0'}" class="show-box justify-content-between">
            <div class="box-value">
                <div :id="'identifier-'+office.public_identifier.replace(' ','')" class="value">
                {{ office.public_identifier.replace(' ','') }}
                </div>
            </div>
            <div class="polygon-container" >
                <svg class="polygon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80">
                    <path d="M48 28L0 55.7128L0 0.287188L48 28Z" :fill="textColor"/>
                </svg>
            </div>
            <div class="box-value">
                <div :id="'counter-'+office.counter_name.replace(' ','')" class="value" style="margin: auto;width: fit-content">
               {{ office.counter_name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LbrxDisplayThirdTheme',
    props: {
        primaryColor: {type: String, required:true},
        secondaryColor: {type: String, required:true},
        textColor:{type: String, required:true},
        data: {type: Array, required: true}
    },
    data() {
        return {
        }
    },
    methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },
    computed: {

        isBigWidth() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
        lineHeight() {
            return 100/this.data.length+"%";
        },
        valueSize() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 3.6+'vw';
                return 5.8+"vw";
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 3.5 + 'vw';
                return 6 + 'vw';
            }
            if(this.isBigWidthMethod())
                return 3.2+'vw';
            return 17/this.data.length+"vw";
        },
    }
}
</script>

<style scoped>
.show-box {
    padding-left: 15px;
    padding-right: 15px;
    height: v-bind(lineHeight);
    width: auto;
    display: flex;
    justify-content: center;
}
.box-value .value {
    margin: auto;
}
.box-value {
    margin-left: 35px;
    margin-right: 35px;
    height:100%;
    display: flex;
    min-width: 100px;
    justify-content: center;
    font-family: 'Digital Numbers',serif;
    font-size: v-bind(valueSize);
    color: v-bind(textColor);
}
.third-display-container {
    overflow: hidden !important;
    height: 100%;
    width: fit-content;
    float: right;
    margin-right: -40px;
    display: flex;
    flex-direction: column;
}
.polygon-container{
    height: 100%;
    display: flex;
    width: 100px;
    margin-left: 6%;
    margin-right: -6%;
    padding-top: 4%;
    justify-content: center;
}
.polygon {
    margin: auto;
}
</style>