import {ApiConfigs, authHeader} from '../_helpers';
import router from "@/router";
import {setLocalVoiceBydisplayLang} from "@/_helpers/voice-helper";

export const displayService = {
    login,
    autoLogin,
    logout,
    refresh,
    getQueueById,
    getWaitingList,
    getInServiceTickets,
    getVoiceTelling,
    ping,
    generateAuthCode,
    confirmAuthByCode
};

function login(key: any, secret: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({key, secret})
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.login}`, requestOptions)
        .then(handleResponse)
        .then(display => {
            // login successful if there's a jwt token in the response
            if (display.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('display', JSON.stringify(display));
            }
            return display;
        });
}

function confirmAuthByCode(payload: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.confirmAuthByCode}`, requestOptions)
        .then(handleResponse);
}

function autoLogin(key: string, token: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.autoLogin
        .replace(':key', key).replace(':token', token)}`, requestOptions)
        .then(handleResponse)
        .then(display => {
            // login successful if there's a jwt token in the response
            if (display.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('display', JSON.stringify(display));
            }
            return display;
        });
}

function logout() {
    // remove user from local storage to log user out
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.logout}`, requestOptions)
        .then(handleResponse)
        .then(function () {
            localStorage.removeItem('token')
            localStorage.removeItem('display')
            localStorage.removeItem('business')
            localStorage.removeItem('counter')
            localStorage.removeItem('number')
        }).then(function () {
            router.push('/login')
        }).catch(handleRejected);
}

function refresh() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: {...authHeader(), 'Content-Type': 'application/json'}
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.refresh}`, requestOptions)
        .then(handleResponse)
        .then(display => {
            localStorage.setItem('display', JSON.stringify(display.display))
            localStorage.setItem('business', JSON.stringify(display.business))
            setLocalVoiceBydisplayLang(JSON.parse(display.display.config).locale)
        }).catch(handleRejected);
}

function getInServiceTickets(queues: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({queues: queues})
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.queues.getInServiceTickets}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getVoiceTelling(data: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.queues.voiceTelling}`, requestOptions).then(handleResponse).catch(handleRejected);
}


function getQueueById(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.queues.get.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getWaitingList(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.queues.getWaitingList.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function ping(payload: any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader()},
        body: payload
    }

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.ping}`, requestOptions)
        .then(handleResponse).catch(handleRejected);
}

function generateAuthCode() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_display_url + ApiConfigs.display.generateAuthCode}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function handleResponse(response: { text: () => Promise<any>; ok: any; status: number; statusText: any; }) {
    return response.text().then(text => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('display');
                // auto logout if 401 response returned from api
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleRejected(exception: any) {
  //  console.log(exception);
}