<template>
    <div style="height: 100%;display: flex;justify-content: right;padding-right: 20px">
    <div class="second-display-container">
            <div v-for="(office,index) in data" :key="index" class="show-box">
                <div class="box-first-value">
                    <div :id="'identifier-'+office.public_identifier.replace(' ','')" class="value">{{ office.public_identifier.replace(' ','') }}</div>
                </div>
                <div style="display: flex;width: 80px !important">
                    <div style="margin: auto">
                        <img src="../assets/icons/biat-arrow.svg"/>
                    </div>
                </div>
                <div class="box-second-value">
                    <div :id="'counter-'+office.counter_name.replace(' ','')" class="value">{{ office.counter_name }}</div>
                </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'LbrxDisplayBiatSecondTheme',
    props: {
        data: {type: Array, required: true}
    },
    data() {
        return {
            offices: this.data,
        }
    }, methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },

    computed: {
        isBigWidth() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },

        getBigWidth() {
            let bigWidth = 3;
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length >bigWidth) {
                    bigWidth = this.data[i].public_identifier.length;
                }
            }
            return 100+(345/bigWidth)+'%'
        },
        lineHeight() {
            if(this.data.length >= 3) {
                return 68 / this.data.length + "vh";
            }
            else if(this.data.length === 2) {
                if(this.isBigWidthMethod()) {
                    return 58 / this.data.length + "vh";
                }
                return 48 / this.data.length + "vh";
            }
            else {
                if(this.isBigWidthMethod()) {
                    return 28 / this.data.length + "vh";
                }
                return 28 / this.data.length + "vh";
            }

        },
        valueSize() {
            if(this.data.length === 1) {
                if(this.isBigWidthMethod())
                    return 3.6+'vw';
                return 5.8+"vw";
            }
            if(this.data.length === 2) {
                if (this.isBigWidthMethod())
                    return 3.5 + 'vw';
                return 6 + 'vw';
            }
            if(this.isBigWidthMethod())
                return 3.2+'vw';
            return 17/this.data.length+"vw";
        },
    }
}
</script>

<style scoped>
.show-box {
    padding-left: 2vmin;
    padding-right: 2vmin;
    margin-bottom: 2vmin;
    height: v-bind(lineHeight);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3vmin;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;
}
.box-second-value {
    height: 100%;
    width: auto;
    min-width: 180px;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    color: #F39800;
}

.box-second-value .value {
    margin: auto;
}

.box-first-value {
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Digital Numbers', serif;
    font-size: v-bind(valueSize);
    color: #244578;
    min-width: 180px;
}
.box-first-value .value {
    margin: auto !important;
}
.second-display-container {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 13px;
    width: fit-content !important;
    float: right;
}

.title-box {
    display: flex;
    width: 78%;
    margin-left: 6vmin;
}
</style>