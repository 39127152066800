<template>
  <div class="footer-container justify-content-between">
    <img class="footer-logo" src="../assets/images/logo_white.png" alt=""/>
    <div class="footer-items main-copyright animate-flicker" >
      {{internet? '':$t('internet_status.off')}}
    </div>
    <div class="footer-items">
      <div class="copyright main-copyright">
        Liberrex.com © Copyright {{ year }}
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: 'TheFooter',
  props: {
    color: {type: String, required: true},
    internet: {type: Boolean, required: true}
  },
  computed: {
    year() {
      const year = moment().year();
      if(parseInt(year) < 2023)
        return "2023";
      return year;
    }
  },
  components: {},
  data() {
    return {
      showLanguageSelect: false,
      showAssistance: false,
    }
  }
}
</script>
<style scoped>

.main-copyright {
  color: #FFFFFF !important;
  margin: auto;
  margin-right: 2vmin;
}

.footer-logo {
  height: 1.8vmin;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2.5vmin
}

.footer-items {
  display: flex;
}

.footer-container {
  width: 100% !important;
  background: linear-gradient(127.19deg, v-bind(color) 0.56%, v-bind(color) 76.01%) !important;
  display: flex;
  min-height: 4vmin;
  padding-left: 1vmin;
  padding-right: 1vmin;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
  font-weight: bold!important;
}
</style>