import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginPage from '../views/login/LoginPage.vue'
import MaintenacePage from "@/views/maintenance/MaintenacePage.vue";
import DisplayPage from "@/views/display/DisplayPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/display"
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: true,
            authorize: ["*"],
        },
        component: MaintenacePage
    },
    {
        path: '/display',
        name: 'display',
        meta: {
            authRequired: true,
            authorize: ["*"],
        },
        component: DisplayPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    const userLoggedIn = localStorage.getItem('display');
    if (to.meta.authRequired && userLoggedIn === null) {
        return next('/login');
    }
    if (to.path == '/login' && userLoggedIn !== null) {
        return next('/');
    }

    if (to.name == "Host") {
        if (!process.env.VUE_APP_EDIT_HOST || process.env.VUE_APP_EDIT_HOST == "false") {
            return next('/display');
        }
    }

    next();
})

export default router
