import {displayService} from "@/_services/display.service";
import router from "@/router";

import english from '../locales/en.json';
import french from '../locales/fr.json';
import arabic from '../locales/ar.json';

export function setLocalVoiceBydisplayLang(language: any) {
    let counter = english.Display.Counter
    let number = english.Display.Number
    const config = JSON.parse(JSON.parse(localStorage.getItem('display') || "").config)
    switch (language) {
        case 'en':
            counter = english.Display.Counter
            number = english.Display.Number
            break;
        case 'fr':
            counter = french.Display.Counter
            number = french.Display.Number
            break;
        case  'ar':
            counter = arabic.Display.Counter
            number = arabic.Display.Number
            break;
        default:
            counter = english.Display.Counter
            number = english.Display.Number
    }

    // if voiceNumber or voiceCounter exists then get them (as they are more primary)
    if (config.voiceNumber !== null) {
        number = config.voiceNumber
    }
    if (config.voiceCounter !== null) {
        counter = config.voiceCounter
    }

    const words = [counter, number]

    words.forEach((word, key) => {
        displayService.getVoiceTelling({
            text: word,
            language: language,
        })
            .then(function (response) {
                localStorage.setItem(
                    key == 0 ? 'counter' : 'number',
                    JSON.stringify({
                        voice: response["audio-content"],
                        language: language,
                    })
                );
            }).then(function () {
            if (words.length === words.indexOf(word) + 1) {
                setInterval(() => {
                    location.reload()
                }, 2000)
            }
        })
    })
}