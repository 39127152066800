<template>
    <div v-if="data" class="vertical-theme-display-container">
        <div class="titles justify-content-between">
            <div class="vert-container first">
                <div class="title">
                    {{firstTitle}}
                </div>
                <div v-for="(office, index) in data" :key="index" class="value left-value">
                    {{office.public_identifier}}
                </div>
            </div>
            <div class="vert-container">
                <div class="title" style="margin-right: 5vmin">
                    {{secondTitle}}
                </div>
                <div v-for="(office, index) in data" :key="index"  class="value">
                    {{office.counter_name}}
                </div>
            </div>
        </div>
        <div style="margin-bottom: -2vmin">
        <the-footer :color="primaryColor" :internet="modelValue"></the-footer>
        </div>

    </div>
</template>

<script>

import TheFooter from "@/components/TheFooter.vue";

export default {
    name: 'LbrxDisplayVerticalTheme',
    components: {TheFooter},
    props: {
        firstTitle: {type: String, required: true},
        secondTitle: {type: String, required: true},
        primaryColor: {type: String, required: true},
        textColor: {type: String, required: true},
        data: {type: Array, required: true},
        modelValue: Boolean,
    },
    data() {
        return {
        }
    },
    methods: {
        isBigWidthMethod() {
            for(let i=0;i<this.data.length;i++) {
                if(this.data[i].public_identifier.length > 3) {
                    return true;
                }
            }
            return false;
        },
    },
    computed: {
        fontSize() {
            if(this.data.length <3) {
                if (this.isBigWidthMethod())
                    return 8 + 'vmin';
                return 17.8 + 'vmin';
            }
            if(this.isBigWidthMethod())
                return 25/this.data.length+'vmin';
            return 65/this.data.length+'vmin';
        },
    }
}
</script>

<style scoped>

.vertical-theme-display-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: v-bind(primaryColor);
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 0 5vmin 5vmin 1vmin;
}
.titles {
    height: 100%;
    display: flex;
    width: 100%;
}
.titles .first {
    width: 50%;
    border-right: 1px solid v-bind(textColor);
}
.title{
    padding-top: 5vmin;
    margin-bottom: 15vmin;
    color:v-bind(textColor);
    width: 100%;
    font-family: 'Digital Numbers',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 5.5vmin;
    line-height: 6vmin;
    text-align: center;
    justify-content: center;
}
.value{
    color:v-bind(textColor);
    margin-bottom: 1vmin;
    font-family: 'Digital Numbers',serif;
    font-style: normal;
    font-weight: 400;
    font-size: v-bind(fontSize);
    text-align: center;
}
.vert-container {
    display: flex;
    flex-direction: column;
}
</style>