function server() {
  if (
    process.env.VUE_APP_EDIT_HOST === "true" &&
    localStorage.getItem("host") != null
  ) {
    return localStorage.getItem("host");
  } else {
    switch (localStorage.getItem("server")) {
      case "tn":
        return process.env.VUE_APP_API_URL_TN;
      case "ci":
        return process.env.VUE_APP_API_URL_CI;
      default:
        return process.env.VUE_APP_API_URL_TN;
    }
  }
}

export const ApiConfigs = {
  base_display_url: server() + "/display",
  broadcast_url: server() + "/broadcasting",
  /* -------------------------------- */
  // PUSHER
  pusher: {
    auth: "/auth",
  },
  //DISPLAY LOGIN
  display: {
    login: "/auth",
    logout: "/logout",
    refresh: "/refresh",
    autoLogin: "/auth/:key/:token",
    ping: "/ping",
    generateAuthCode: "/auth/generate",
    confirmAuthByCode: "/auth/confirm",
  },

  /* -------------------------------- */
  // CUSTOMERS
  customers: {
    getAll: "/customer?pagination=1000",
    get: "/customer/:id",
    pagination: "/customer?page=",
    filter: "/customer/filter/",
    create: "/customer/create",
    update: "/customer/:id/update",
    _delete: "/customer/:id/delete",
  },
  /* -------------------------------- */

  // QUEUES
  queues: {
    get: "/queue/:id",
    getForDispay: "/queue/:id",
    getInServiceForDisplay: "/queue/:id/inservice",
    getWaitingListForDisplay: "/queue/:id/waitinglist",
    getInServiceTickets: "/queues/inServiceTickets",
    voiceTelling: "/voice",
    getWaitingList: "/queue/:id/waitinglist",
  }
};
