<template>
    <div v-if="timer" class="circle" :style="{'--clr':color}">
        <div class="timer" :style="{'color': color}">
            <div id="seconds">{{ currentTimer }}</div>
            <div>sec</div>
        </div>
        <svg>
            <circle r="43" cy="43" cx="43"></circle>
            <circle :class="{'stroke-animation': modelValue}" r="43" cy="43" cx="43" id="counter"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LbrxCountdown',
    props: {
        color: String,
        timer: Number,
        modelValue: Boolean
    },
    emits: ['counter-ended'],
    data() {
        return {
            currentTimer: 0,
            timerInterval: null,
        }
    },
    created() {

      this.currentTimer = this.timer
      this.timerInterval = setInterval(() => {
        this.currentTimer--;
        if (this.currentTimer <= 0) {
          this.currentTimer = 0;
          clearInterval(this.timerInterval);
          setTimeout(() => {
            this.$emit('counter-ended');
          }, 200)
        }
      }, 1000);
    },
    computed: {
        animationDuration() {
            return `${this.timer}s`;
        },
    },
}
</script>

<style scoped>
.circle {
    margin-top: 3vmin;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

svg {
    position: relative;
    height: 97px;
    width: 97px;
    transform: rotate(270deg);
}

svg circle {
    width: 100%;
    height: 100%;
    fill: transparent;
    stroke: var(--clr);
    stroke-width: 8.7;
    transform: translate(5px, 5px);
}

@keyframes dash-offset-animation {
    from {
        stroke-dashoffset: -270;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.stroke-animation {
    animation: dash-offset-animation linear forwards;
    animation-duration: v-bind(animationDuration);
}

svg circle:nth-child(2) {
    stroke-width: 10;
    stroke: white;
    stroke-dasharray: 270;
    stroke-dashoffset: -270;
}

#seconds {
    height: 55px;
    width: 70px;
    letter-spacing: 1px;
}

.timer {
    font-family: 'Exo 2', serif;
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    font-weight: 600;
    color: green;
    font-size: 1.6em;
    line-height: 2em;
}

#seconds {
    margin-bottom: -40%;
}
</style>