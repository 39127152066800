export function authHeader(): { [key: string]: string } {
    // return authorization header with jwt token
    const token =localStorage.getItem('token') || 'null';


    if (token!== null) {
        return {
            'Authorization': 'Bearer ' + token,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'fr'
        };
    } else {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'fr'
        };
    }
}