<template>
    <div class="header-container" :style="theme == 'dynamicStyle' ? 'justify-content: center;' : ''">
        <div style="margin-top: auto;margin-bottom: auto;height: 100%"><img
                class="logo"
                :src="image ? image : '/images/biat-logo.png'" ></div>
    </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";
import router from "@/router";
export default {
    name: 'LbrxDisplayHeader',
    props: {
        image: {
            required: false,
            value: String
        },
        theme: {
            required: false,
            value: String
        }
    },
    computed: {
        makeColorDarker() {
            return colorShader.methods.newShade(this.primaryColor, -50);
        },
    }
}
</script>

<style scoped>
.header-container {
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    background: #FFFFFF !important;
    display: flex;
    width: 100%;
    height: 7.8%;
}

.header-container .logo {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4vmin;
    height: 140%;
    margin-top: -1%; 
    margin-left: 3vmin;
}

@media (max-width:900px){
    .header-container .logo {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0;
        height: 100%;
        margin-top: 0%; 
        margin-left: 0vmin;
    }
}
</style>